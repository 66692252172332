import React from "react";
import { Form, Input, message } from "antd";
import "./index.less";

const ModalComponent = Loader.loadBaseComponent("ModalComponent");

const FormItem = Form.Item;

class ModifyPassword extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    confirmDirty: false,
    formKey: Math.random()
  };
  handleCancel(isChangePassword) {
    this.props.onCancel(isChangePassword);
    setTimeout(() => {
      this.setState({
        formKey: Math.random()
      });
    }, 500);
  }
  handleConfirmBlur(e) {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }
  compareToFirstPassword(rule, value, callback) {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("newPassword")) {
      callback("密码不一致!");
    } else {
      callback();
    }
  }

  validateToNextPassword(rule, value, callback) {
    const form = this.props.form;
    const regs = [/[a-z]/g, /[A-Z]/g, /[0-9]/g, /[`~!@#$%^&*()_\-+=<>?:"{}|,.\\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g];
    const flags = regs.map(v => v.test(value));
    const flag = flags.filter(v => v).length < 3 || value.length < 8 || value.length > 18;
    if (flag) {
      callback("密码为8-18位，包含大写字母，小写字母，数字，特殊字符中的三种");
    }
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  }

  handleSubmit(e) {
    const { form, isRedirect = true } = this.props;
    return new Promise((resolve, reject) => {
      form.validateFieldsAndScroll((err, values) => {
        if (err) {
          reject(err);
          return;
        }
        Service.user
          .changePassword({
            id: Utils.getCache("userId", "session"),
            oldPassword: values.oldPassword,
            newPassword: values.newPassword
          })
          .then(() => {
            message.success("密码修改成功!");
            setTimeout(() => BaseStore.user.logout({ isRedirect, isLogin: true }), 100);
            this.handleCancel(true);
            resolve();
          })
          .catch(err => {
            let msg;
            try {
              msg = err.data.message;
            } catch (e) {
              msg = "密码修改失败！";
            }
            message.error(msg);
            reject(err);
          });
      });
    });
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };
    return (
      <ModalComponent
        {...this.props}
        className={`change-psw-modal ${this.props.className ? this.props.className : ""}`}
        title={this.props.title}
        visible={this.props.visible}
        maskClosable={this.props.maskClosable}
        closable={this.props.closable}
        onOk={this.handleSubmit.bind(this)}
        onCancel={this.handleCancel.bind(this, false)}
        okText="修改密码"
        cancelText="取消"
        key={this.state.formKey}
      >
        <Form>
          <FormItem {...formItemLayout} label="旧密码">
            {getFieldDecorator("oldPassword", {
              rules: [
                {
                  required: true,
                  message: "请输入旧密码!"
                }
              ]
            })(<Input type="password" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="新密码">
            {getFieldDecorator("newPassword", {
              rules: [
                {
                  required: true,
                  message: "请输入新密码!"
                },
                {
                  validator: this.validateToNextPassword.bind(this)
                }
              ]
            })(<Input type="password" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="重复新密码">
            {getFieldDecorator("confirm", {
              rules: [
                {
                  required: true,
                  message: "请重复新密码!"
                },
                {
                  validator: this.compareToFirstPassword.bind(this)
                }
              ]
            })(<Input type="password" onBlur={this.handleConfirmBlur.bind(this)} />)}
          </FormItem>
        </Form>
      </ModalComponent>
    );
  }
}
export default Form.create()(ModifyPassword);
